<template>
    <div :class="'text-'+color" class="flex w-100 justify-center items-center space-x-2 text-baselg h-12">
        <p>{{getCurrentNumber}}</p>
        <hr class="w-14 text-black">
        <p>{{totalNumber}}</p>
    </div>
</template>

<script>
    export default {
        name: "NavigationIndex",
        props: {
            currentNumber:Number,
            totalNumber: Number,
            color: String
        },
        computed:{
            getCurrentNumber(){
                return this.currentNumber
            }
        }
    }
</script>