import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Districts action called.`)
  },

  methods: {
    ...mapActions(['getDistricts', 'getDistrict']),
  }
}

export default mixin